import React from "react"
import styled from "styled-components"
import { MdDone } from "react-icons/md"

export default function ContactSuccess() {
  return (
    <SuccessBox>
      <MdDone className="tick" size="5em" />
      <h3>Thanks for getting in touch!</h3>
      <p>We will get back to you as soon as possible.</p>
    </SuccessBox>
  )
}

const SuccessBox = styled.div`
  height: 22em;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  text-align: center;

  h3 {
    color: #2d3e52;
    font-size: 2em;
    font-weight: 500;
  }

  p {
    font-size: 1.2em;
    margin-top: 20px;
  }

  .tick {
    color: green;
  }
`
