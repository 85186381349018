import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ContactSuccess from "../components/contactSuccess"
import SEO from "../components/seo"
import api from "../api"
import Valid from "../components/validator"
import theme from "../theme"
import { Button } from "../components/ui/buttons"

const valid = new Valid()

// TODO Add styles to invalid inputs

export default function Contact() {
  const [formValues, setFormValues] = useState({
    type: "",
    firstName: "",
    lastName: "",
    email: "",
    bookingRef: "",
    message: "",
  })

  const [formErrors, setFormErrors] = useState({
    type: false,
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  })

  const [isSubmitting, toggleSubmitting] = useState(false)
  const [isvalid, setValid] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  useEffect(() => {
    const result = valid.formValid(formValues, formErrors)
    setFormErrors(result.formErrors)
    setValid(result.valid)
  }, [formErrors, formValues])

  const submitSalesforce = (e) => {
    e.preventDefault()

    // Check if form is valid
    const result = valid.formValid(formValues, formErrors)
    let errors = result.formErrors

    // Send email if form is valid
    if (result.valid) {
      toggleSubmitting(true)
      let bodyFormData = new FormData()

      bodyFormData.set("name", formValues.firstName + " " + formValues.lastName)
      bodyFormData.set("email", formValues.email)
      bodyFormData.set("type", formValues.type)
      bodyFormData.set("description", formValues.message)
      bodyFormData.set("00N6g00000ThC0q", formValues.bookingRef)

      api
        .post(
          "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
          bodyFormData
        )
        .then((res) => {
          toggleSubmitting(false)
          setSubmitSuccess(true)
        })
        .catch((err) => {
          toggleSubmitting(false)
        })
    } else {
      // if form is not valid, update errors
      setFormErrors(errors)
      console.log("error")
    }
  }

  const handleChange = (e) => {
    let { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  return (
    <>
      <SEO title="Contact Us" />
      <Page>
        <div className="container">
          <div className="column left">
            <h1>Contact Us</h1>
            {submitSuccess ? (
              <ContactSuccess />
            ) : (
              <div className="form">
                <form onSubmit={submitSalesforce}>
                  <div
                    className={
                      formErrors.type ? "radios radios-error" : "radios"
                    }
                  >
                    <input
                      type="radio"
                      name="type"
                      value="bookings"
                      onChange={handleChange}
                    />
                    <p>Bookings</p>
                    <input
                      type="radio"
                      name="type"
                      value="payments"
                      onChange={handleChange}
                    />
                    <p>Payments</p>
                    <input
                      type="radio"
                      name="type"
                      value="general"
                      onChange={handleChange}
                    />
                    <p>General/Media Inquiry</p>
                  </div>
                  <div className="form-fields">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      name="bookingRef"
                      placeholder="Booking reference number (if any)"
                      onChange={handleChange}
                    />
                    <textarea
                      type="text"
                      name="message"
                      placeholder="How can we help you?"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <Button
                    type="submit"
                    purple
                    large
                    disabled={isSubmitting || !isvalid}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </form>
              </div>
            )}
          </div>
        </div>
      </Page>
    </>
  )
}

const Page = styled.div`
  min-height: calc(100vh - 65px);
  color: #333;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .left {
      flex-grow: 3;
      padding-right: 2rem;

      @media only screen and (max-width: 905px) {
          padding-right: 0;
      }

      h1 {
        color: ${theme.fontColourDarkBlue};
        font-weight: 400;
      }
    }

    .right {
      flex-grow: 1;
      min-height: 20em;

      @media only screen and (max-width: 918px) {
        margin: 2rem 0;
      }

      iframe {
        height: 100%;
        width: 100%;
      }
    }

    .form {
      margin: 3rem 0;

      .radios {
        display: flex;

        &.radios-error {
          color: red;
        }

        @media only screen and (max-width: 459px) {
                font-size: ${theme.fontSizeSmall};
                align-items: center;
        }

        p {
          margin-right: 1.5rem;
        }

        input {
          margin-right: 0.5rem;
        }
      }

      .form-fields {
        margin: 1rem 0;
        display: grid;
        grid-row-gap: 0.5rem;
        grid-column-gap: 0.5rem;
        grid-template-rows: 1fr 1fr 3fr;
        grid-template-areas: "firstName lastName" "email bookingRef" "desc desc";

        input, textarea[name="message"] {
          font-weight: 500;
          font-family: ProximaNova;
          color: #838383;
        }

        input[name="firstName"] {
          grid-area: firstName;
        }

        input[name="lastName"] {
          grid-area: lastName;
        }

        input[name="email"] {
          grid-area: email;
        }

        input[name="bookingRef"] {
          grid-area: bookingRef;
        }

        textarea[name="message"] {
          grid-area: desc;
          /* font-family: ProximaNova; */
        }

        input,
        textarea {
          border-radius: 5px;
          border: 1px solid #dedede;
          padding: 10px;
          font-size: 15px;
        }

        @media only screen and (max-width: 459px) {
            grid-template-rows: 1fr 1fr 1fr 1fr 2fr;
            grid-template-areas: "firstName" "lastName" "email" "bookingRef" "desc";

            .radios {
                font-size: ${theme.fontSizeTiny};
                color: red;
            }
        }
      }

      button {
        width: 8em;
      }

      }
    }

    .contact {
      display: flex;

      @media only screen and (max-width: 705px) {
        flex-direction: column;
      }

      .area {
        flex-grow: 1;

        h4 {
          margin-bottom: 1rem;
          font-size: ${theme.fontSizeHeadingMed};
          font-weight: 500;
        }

        p,
        a {
          font-size: ${theme.fontSizeLead};
          font-weight: 200;
          line-height: 1.625;
        }

        p {
          color: ${theme.fontColourLight};
          font-weight: 500;
        }

        a {
          text-decoration: none;
          color: ${theme.orange};
        }
      }

      @media only screen and (max-width: 705px) {
        .area {
          h4 {
            font-size: ${theme.fontSizeLead};
          }

          p,
          a {
            font-size: ${theme.fontSizeSmall};
          }
        }
      }
    }
  }
`
